.artist-cards-container {
    display: flex;
    flex-direction: column;
    margin: 0 3em;
}

.desktop-title {
    font-family: 'freehouse', serif;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    font-size: 6vh;
}

.back-link {
    color: white;
    align-self: flex-start;
    font-size: large;
    background-color: var(--primary-dark-blue);
    padding: 0.5em;
    border-radius: 30px;
    transition: 0.25s ease-in-out;
    text-decoration: none;
    margin: 3em 0 3em 10em;
}

.back-link:hover {
    cursor: pointer;
    background-color: var(--primary-black);
}

.artist-button {
    background-color: var(--primary-dark-blue);
    width: min(50vw, 500px);
    height: 20vh;
    max-height: 150px;
    display: flex;
    align-items: center;
    transition: 0.25s ease-in-out;
    font-family: "freehouse", serif;
    border-radius: 8em;
    margin: 4em;
}

.artist-button:nth-child(odd) {
    flex-direction: row-reverse;
}

.artist-button-title {
    font-size: large;
    width: 30vw;
    text-align: center;
}

.artist-button:hover .artist-button-img {
    border: 8px solid var(--primary-dark-blue);
}

.artist-button-img {
    height: 30vh;
    max-height: 300px;
    border-radius: 10em;
    transition: 0.25s ease-in-out;
    border: 8px solid var(--primary-black);
}

.artist-button:hover {
    cursor: pointer;
    background-color: var(--primary-black);
}

.artist-button-icon {
    transform: rotate(-90deg);
}

.artwork-by-text {
    font-size: 5vh;
    text-align: center;
    font-family: 'freehouse', serif;
}

.section-title.artists-title {
    padding: 0.5em 0 1em 0;
}

.main-content {
    height: 100vh;
    overflow-y: auto;
    background-color: var(--primary-dark-blue);
    background: url('assets/images/artists/defiant-hair-artists-background.png') center -20vh no-repeat;
    background-size: 100vw;
    overflow-x: hidden;
}

.artist-gallery {
    height: 20vh;
}

.artist-img {
    width: 28vw;
    overflow: hidden;
    align-self: center;
    border-radius: 50%;
    border: 15px solid var(--primary-black);
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.artists-content,
artist-content {
    display: flex;
    flex-direction: column;
    max-width: 40em;
    align-self: center;
    align-items: center;
    width: 100%;
}

.artist-content {
    padding-top: 8vh;
}

.artists-main {
    padding-top: 4vh;
}

.artwork-container {
    width: 60vw;
    padding: 1em;
    align-self: center;
    border: 3px dotted var(--primary-dark-blue)
}

.name-and-image {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 1em;
    align-items: center;
}

.pronouns-title {
    font-size: 4vh;
    font-family: 'freehouse', serif;
    font-style: normal;
}

.artist-title {
    font-size: 3vh;
    font-family: 'freehouse', serif;
    font-style: normal;
}

.artists-backdrop,
.artist-backdrop {
    color: white;
    flex-direction: column;
    display: flex;
    justify-content: center;
    backdrop-filter: brightness(0.6);
    -webkit-backdrop-filter: brightness(0.6)
}

@media only screen and (max-width: 900px) {
    .artwork-container {
        max-width: 70vw;
    }

    .artists-main {
        padding-top: 8vh;
    }

    .desktop-title {
        font-size: 4vh;
    }

    .pronouns-title {
        font-size: 2vh;
    }

    .artwork-by-text {
        font-size: 3vh;
    }

    .artist-img {
        width: 60vw;
    }

    .main-content {
        background-size: 100vh;
        background-position: center 15vh;
    }

    .artist-cards-container {
        padding-bottom: 10em;
    }
}

@media only screen and (max-width: 450px) {
    .back-link {
        margin: 15em 0 3em 1em;
    }

    .artist-button:nth-child(odd) .artist-button-title {
        padding-right: 1em;
    }

    .artist-button:nth-child(even) .artist-button-title {
        padding-left: 1em;
    }

    .artist-button {
        width: 90vw;
        height: 20vh;
    }

    .artists-backdrop {
        height: 100%;
    }

    .artist-title {
        font-size: 2vh;
    }

    .artist-button-img {
        height: 25vh;
    }
}