.home-container {
    height: 100vh;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    background: url('assets/images/window-with-logo.jpg') center;
}

.home-title-content {
    color: white;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.main-heading {
    font-family: "freehouse", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 6.5rem;
    text-align: center;
    opacity: 0;
    animation: fadeIn 2.5s 2s forwards;
}

.page-section {
    backdrop-filter: brightness(0.7);
    -webkit-backdrop-filter: brightness(0.7);
}

.page-section.contact {
    background: url('assets/images/lauren-with-dogs.jpg') center no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.page-section.hours-and-location {
    background: url('assets/images/salon-outside-with-sign.jpg') center no-repeat;
    background-size: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: white;
}

.see-more-reviews-link {
    color: white;
    font-size: large;
    text-align: center;
}

.reviews-container {
    padding: 1em 0;
    max-width: 60em;
    align-self: center;
}

.home-container::before {
    backdrop-filter: brightness(0.7);
    -webkit-backdrop-filter: brightness(0.7);
}

.page-section::before {
    backdrop-filter: brightness(0.7);
    -webkit-backdrop-filter: brightness(0.7);
}

.home-container::before,
.page-section::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: block;
}

.home-sub-container {
    display: flex;
    flex-direction: column;
}

.sub-heading-text.art {
    animation: fadeIn 0.5s 2s forwards;
}

.sub-heading-text.hair {
    animation: fadeIn 0.5s 2.25s forwards;
}

.sub-heading-text.inspo {
    animation: fadeIn 0.5s 2.5s forwards;
}

.sub-heading {
    font-family: 'freehouse', serif;
    font-weight: 100;
    font-style: normal;
    padding: 0.5em;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}

.scroll-down-icon {
    animation: bounce 2s ease-in-out infinite;
    cursor: pointer;
    margin-top: 1em;
    height: 2em;
    width: 2em;
}

.scroll-down-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    opacity: 0;
    animation: fadeIn 0.5s 3.25s forwards;
}

.scroll-down-icon.hide {
    display: none;
}

.book-button-parent {
    display: flex;
    justify-content: center;
    align-content: center;
    opacity: 0;
    animation: fadeIn 0.5s 2.75s forwards;
}

.lightning-icon {
    height: 2em;
    width: 2em;
    margin: 0.75em 0 0 0;
    opacity: 0;
    animation: fadeInFromTop 0.25s 2.75s forwards;
}

.section-title {
    align-self: center;
    text-align: center;
    font-size: 5em;
    padding-bottom: 0.5em;
    font-family: 'freehouse', serif;
    font-style: normal;
}

.who-we-are-main-content {
    background-color: var(--primary-dark-blue);
    padding: 0.5em 1em;
    max-width: 40em;
    align-self: center;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.who-we-are.section-title,
.who-we-are-main-content {
    opacity: 0;
}

.who-we-are.section-title.fadeIn {
    animation: fadeInFromTop 1s 0.5s forwards;
}

.who-we-are-main-content.fadeIn {
    animation: fadeInFromBottom 1s 1.5s forwards;
}

.review {
    opacity: 0;
    background-color: var(--primary-dark-blue);
    font-size: x-large;
    margin: 0.5em;
    padding: 0.5em;
    text-align: left;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.review.fadeIn {
    animation: fadeInFromLeft 1s forwards 0.25s;
}

.review:nth-child(2).fadeIn {
    animation: fadeInFromRight 1s forwards 0.25s;
}

.review:nth-child(3).fadeIn {
    animation: fadeInFromLeft 1s forwards 0.25s;
}

.vines {
    display: flex;
    align-items: center;
}

.vine {
    width: 50vw;
    animation: leftToRightFadeIn 1s ease-in;
    mask-repeat: no-repeat;
}

.mini-logo {
    height: 45vh;
    max-height: 300px;
    border-radius: 10%;
    opacity: 0;
    animation: fadeIn 0.25s ease-in 1s forwards;
}

.vine-mobile {
    display: none;
    height: 14em;
    animation: rightToLeftFadeIn 1s ease-in;
    mask-repeat: no-repeat;
}

.vine-mobile.flipped {
    transform: scaleX(-1) scaleY(-1);
}

.vine.flipped {
    transform: scaleX(-1);
}

.who-we-are-text {
    font-size: x-large;
    padding: 0.5em;
}

.hours-and-location-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.page-section.reviews {
    backdrop-filter: brightness(1);
    -webkit-backdrop-filter: brightness(1);
    min-height: 100vh;
    color: white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 1em;
    padding: 1em;
}

.page-section.who-we-are {
    background: url('assets/images/matches-and-pic.jpg') no-repeat right, url('assets/images/mari-scissors.jpg') no-repeat left;
    background-size: 50vw;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: white;
    height: 100vh;
    width: 100%;
}

.sub-heading-text {
    font-size: xx-large;
    opacity: 0;
}

@keyframes leftToRightFadeIn {
    0% {
        mask-size: 0%;
        mask-image: linear-gradient(to right,
                rgba(0, 0, 0, 1) 70%,
                rgba(0, 0, 0, 0));
    }

    100% {
        mask-size: 100%;
        mask-image: linear-gradient(to right,
                rgba(0, 0, 0, 1) 70%,
                rgba(0, 0, 0, 0));
    }
}

@keyframes rightToLeftFadeIn {
    0% {
        mask-size: 0%;
        mask-image: linear-gradient(to right,
                rgba(0, 0, 0, 1) 70%,
                rgba(0, 0, 0, 0));
    }

    100% {
        mask-size: 100%;
        mask-image: linear-gradient(to right,
                rgba(0, 0, 0, 1) 70%,
                rgba(0, 0, 0, 0));
    }
}

@keyframes fadeInSlideRight {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateX(4em);
    }
}

@keyframes fadeInSlideLeft {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateX(-4em);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInFromTop {
    0% {
        opacity: 0;
        transform: translateY(-1em);
    }

    100% {
        opacity: 1;
        transform: translateY(0em);
    }
}

@keyframes fadeInFromBottom {
    0% {
        opacity: 0;
        transform: translateY(2em);
    }

    100% {
        opacity: 1;
        transform: translateY(0em);
    }
}

@keyframes fadeInFromRight {
    0% {
        opacity: 0;
        transform: translateX(-2em);
    }

    100% {
        opacity: 1;
        transform: translateX(0em);
    }
}

@keyframes fadeInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(2em);
    }

    100% {
        opacity: 1;
        transform: translateX(0em);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-5px);
    }
}

@media only screen and (max-width: 900px) {
    .container {
        width: 100vw;
    }

    .mini-logo {
        height: 25vh;
    }

    .who-we-are-text {
        font-size: large;
    }

    .hours-and-location-content {
        flex-direction: column;
    }

    .page-section.contact {
        background-size: 160vh;
    }

    .page-section.hours-and-location {
        background-size: 150vh;
    }

    .main-heading {
        font-size: 3.5rem;
        margin-top: 1.5em;
    }

    .sub-heading-text {
        font-size: large;
    }

    .section-title {
        font-size: 3rem;
    }

    .scroll-down-container {
        margin: 0;
    }

    .scroll-down-icon {
        margin: 1em;
    }

    .review {
        font-size: large;
    }

    .page-section.who-we-are {
        background-size: 100vh;
    }
}

@media only screen and (max-width: 450px) {
    .main-heading {
        font-size: 2rem;
        margin-top: 2em;
    }

    .vine {
        display: none;
    }

    .vine-container {
        position: relative;
        top: 8em;
        left: 1em
    }

    .vine-container.flipped {
        position: relative;
        top: -8em;
        left: -0.5em;
    }

    .vines {
        flex-direction: column;
        margin-bottom: -10em;
        margin-top: -8em;
    }

    .vine-mobile {
        display: inherit;
    }

    .sub-heading-text {
        font-size: medium;
    }

    .section-title {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 330px) {

    .vine-mobile {
        display: inherit;
        height: 6.5em;
    }

    .scroll-down-icon {
        margin: 0;
        height: 1.5em;
        width: 1.5em;
    }

    .mini-logo {
        height: 18vh;
    }

    .vine-container {
        position: relative;
        top: 4em;
        left: 0.35em
    }

    .vine-container.flipped {
        position: relative;
        top: -4em;
        left: -0.35em;
    }

    .vines {
        flex-direction: column;
        margin-bottom: -4em;
        margin-top: -3em;
    }

    .vine {
        display: none;
    }
}